.App {
  text-align: center;
}

.heading {
  background-color: lightgrey;
  padding: 20px;
}

.cardStyle {
  display: inline-block;
  padding: 50px;
  border: 1px solid grey;
  box-shadow: 10px 10px 10px grey;
  margin: 40px;
  width: 20%;
  border-radius: 30px;
}

.cases {
  background-color: lavender;
}

.deaths {
  background-color: lightsalmon;
}

.recovered {
  background-color: lightgreen;
}

.bglightgrey{
  background-color: lightgrey;
}


/* @media only screen and (max-width: 600px) {
  .cardStyle{
    width: 30%;
    margin-left: 0px;
  }
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
